/**
 * Page Template
 * @Note - This template is only used to store data. A separate template is used
 *         for presentation. This is so that we can allow for real-time previews
 *         in a separate environment for content editors.
 */

import * as React from 'react';
import { graphql } from 'gatsby';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../presentation/page';

const PageTemplate = ({ data, pageContext, path }) => {
  return (
    <Page
      data={{ ...data.page, staff: data.staff }}
      slug={pageContext.slug}
      path={path}
    />
  );
};

export default PageTemplate;

export const query = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
      }
      acfGenericPage {
        overviewTitle
        overviewBody
        overviewForeword
      }
      wpParent {
        node {
          ... on WpPage {
            id
            title
            slug
            wpChildren {
              nodes {
                ... on WpPage {
                  id
                  title
                  slug
                }
              }
            }
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            title
            slug
          }
        }
      }
    }

    staff: allWpStaffMember {
      edges {
        node {
          id
          title
          slug
          staffAcf {
            jobCategory
            jobTitle
            order
            profileImage {
              sourceUrl
            }
          }
          staffCard {
            cardSummaryText
            hideProfileLink
          }
        }
      }
    }
  }
`;
